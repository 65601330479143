import './CourseMenu.css';
import {
  Link
} from "react-router-dom";
import Data from "../data/Data";

function CourseMenu() {
  return (
    <div className="course-menu">
      <div className="body-container flex-center">
        <div className="links-container">
          {Data.courses.map((course) =>
            <Link key={course.id} to={`/golf-meter/${course.id}/1`}>
              <div className="link-text-container flex-center shadow">
                  <h3 className="no-margin-top no-margin-bottom">{course.name}</h3>
              </div>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default CourseMenu;