import './GolfMeter.css';
import React, { useState, useEffect } from 'react';
import Data from '../data/Data';
import {
  useParams,
  Link
} from "react-router-dom";
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";
import { BiSearchAlt } from "react-icons/bi";

function GolfMeter() {
  let { courseId, holeNumber } = useParams();

  courseId = parseInt(courseId, 10);
  holeNumber = parseInt(holeNumber, 10);

  const [distanceToHole, setDistanceToHole] = useState('-');
  const [accuracy, setAccuracy] = useState('-');

  function measureMetersBetween(lat1, lon1, lat2, lon2) {
      var R = 6378.137;
      var dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
      var dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
      var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon/2) * Math.sin(dLon/2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = R * c;
      return d * 1000;
  }

  useEffect(() => {
    const watchPositionHandlerId = navigator.geolocation.watchPosition((pos) => {
        const currentHole = Data.courses.filter((course) => course.id === courseId)[0].holes.filter((hole) => hole.number === holeNumber)[0];
  
        const distanceToHole = Math.round(measureMetersBetween(pos.coords.latitude, pos.coords.longitude, currentHole.latitude, currentHole.longitude));
    
        setDistanceToHole(distanceToHole);

        setAccuracy(Math.round(pos.coords.accuracy));
    }, (err) => {
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    });

    return () => {
      navigator.geolocation.clearWatch(watchPositionHandlerId);
    };
  }, [courseId, holeNumber]);

  return (
    <div className="golf-meter">
      <div className="header-container flex-center">
        <div className="title-container">
          <h3>{Data.courses.filter((course) => course.id === courseId)[0].name}</h3>
        </div>
      </div>
      <Link to="/">
        <div className="search-container flex-center shadow">
            <BiSearchAlt size="1.9em" color="#FFFFFF" />
        </div>
      </Link>
      <div className="body-container flex-center">
        <div className="arrow-container">
          {holeNumber > 1 && 
            <div className="pull-left">
              <Link to={`/golf-meter/${courseId}/${holeNumber - 1}`}>
                <BsFillCaretLeftFill size="2.5em" />
              </Link>
            </div>
          }
        </div>
        <div className="meter-container text-center">
          <span className="hole-title">Hål {holeNumber}</span>
          <div className="horizontal-line"></div>
          <span className="distance-title">{distanceToHole} m</span>
          <h4 className="no-margin-top"> (+- {accuracy} m till centrum på green)</h4>
        </div>
        <div className="arrow-container">
          {holeNumber < Data.courses.filter((course) => course.id === courseId)[0].holes.length &&
            <div className="pull-right">
              <Link to={`/golf-meter/${courseId}/${holeNumber + 1}`}>
                <BsFillCaretRightFill size="2.5em" />
              </Link>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default GolfMeter;