import './App.css';
import CourseMenu from './course-menu/CourseMenu';
import GolfMeter from './golf-meter/GolfMeter';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Logo from './logo/Logo';

function App() {
  return (
    <div className="app">
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={CourseMenu} />
            <Route path="/golf-meter/:courseId/:holeNumber" component={GolfMeter} />
          </Switch>
          <div className="logo-container">
            <Logo iconSize="4em" color="#FFFFFF" fontSize="1.7em" />
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;