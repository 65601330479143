const Data = {
  courses: [
    {
      id: 1,
      name: "Linköpings GK",
      holes: [
        {
          number: 1,
          latitude: 58.40201,
          longitude: 15.564877
        },
        {
          number: 2,
          latitude: 58.401151,
          longitude: 15.558779
        },
        {
          number: 3,
          latitude: 58.402116,
          longitude: 15.556832
        },
        {
          number: 4,
          latitude: 58.402340,
          longitude: 15.563871
        },
        {
          number: 5,
          latitude: 58.402681,
          longitude: 15.566886
        },
        {
          number: 6,
          latitude: 58.403055,
          longitude: 15.559944
        },
        {
          number: 7,
          latitude: 58.403472,
          longitude: 15.562484
        },
        {
          number: 8,
          latitude: 58.404194,
          longitude: 15.568750
        },
        {
          number: 9,
          latitude: 58.401455,
          longitude: 15.569489
        },
        {
          number: 10,
          latitude: 58.396928,
          longitude: 15.570025
        },
        {
          number: 11,
          latitude: 58.395104,
          longitude: 15.569114
        },
        {
          number: 12,
          latitude: 58.397359,
          longitude: 15.565935
        },
        {
          number: 13,
          latitude: 58.400540,
          longitude: 15.560475
        },
        {
          number: 14,
          latitude: 58.400964,
          longitude: 15.568953
        },
        {
          number: 15,
          latitude: 58.398344,
          longitude: 15.564765
        },
        {
          number: 16,
          latitude: 58.396702,
          longitude: 15.567809
        },
        {
          number: 17,
          latitude: 58.398545,
          longitude: 15.566380
        },
        {
          number: 18,
          latitude: 58.400709,
          longitude: 15.569537
        }
      ]
    },
    {
      id: 2,
      name: "Vårdsbergs GK",
      holes: [
        {
          number: 1,
          latitude: 58.411068,
          longitude: 15.753199
        },
        {
          number: 2,
          latitude: 58.409587,
          longitude: 15.750696
        },
        {
          number: 3,
          latitude: 58.412270,
          longitude: 15.744463
        },
        {
          number: 4,
          latitude: 58.412160,
          longitude: 15.742242
        },
        {
          number: 5,
          latitude: 58.414945,
          longitude: 15.743079
        },
        {
          number: 6,
          latitude: 58.413953,
          longitude: 15.745445
        },
        {
          number: 7,
          latitude: 58.413409,
          longitude: 15.744045
        },
        {
          number: 8,
          latitude: 58.410638,
          longitude: 15.749825
        },
        {
          number: 9,
          latitude: 58.411647,
          longitude: 15.749664
        }
      ]
    },
    {
      id: 3,
      name: "Vreta Kloster 9-hålsbanan",
      holes: [
        {
          number: 1,
          latitude: 58.522769,
          longitude: 15.522397
        },
        {
          number: 2,
          latitude: 58.523833,
          longitude: 15.520095
        },
        {
          number: 3,
          latitude: 58.522099,
          longitude: 15.518472
        },
        {
          number: 4,
          latitude: 58.520938,
          longitude: 15.516530
        },
        {
          number: 5,
          latitude: 58.522197,
          longitude: 15.521451
        },
        {
          number: 6,
          latitude: 58.520805,
          longitude: 15.518287
        },
        {
          number: 7,
          latitude: 58.521872,
          longitude: 15.522842
        },
        {
          number: 8,
          latitude: 58.524466,
          longitude: 15.522228
        },
        {
          number: 9,
          latitude: 58.526397,
          longitude: 15.519183
        }
      ]
    },
    {
      id: 4,
      name: "Nora Gk",
      holes: [
        {
          number: 1,
          latitude: 59.501206,
          longitude: 15.086615
        },
        {
          number: 2,
          latitude: 59.501835,
          longitude: 15.082478
        },
        {
          number: 3,
          latitude: 59.502479,
          longitude: 15.089469
        },
        {
          number: 4,
          latitude: 59.498578,
          longitude: 15.084904
        },
        {
          number: 5,
          latitude: 59.497837,
          longitude: 15.085467
        },
        {
          number: 6,
          latitude: 59.497518,
          longitude: 15.092060
        },
        {
          number: 7,
          latitude: 59.501989,
          longitude: 15.089700
        },
        {
          number: 8,
          latitude: 59.502752,
          longitude: 15.082541
        },
        {
          number: 9,
          latitude: 59.498955,
          longitude: 15.079802
        },
        {
          number: 10,
          latitude: 59.496545,
          longitude: 15.081411
        },
        {
          number: 11,
          latitude: 59.497866,
          longitude: 15.079378
        },
        {
          number: 12,
          latitude: 59.494897,
          longitude: 15.077632
        },
        {
          number: 13,
          latitude: 59.496494,
          longitude: 15.077383
        },
        {
          number: 14,
          latitude: 59.494258,
          longitude: 15.075495
        },
        {
          number: 15,
          latitude: 59.492864,
          longitude: 15.084781
        },
        {
          number: 16,
          latitude: 59.495095,
          longitude: 15.082193
        },
        {
          number: 17,
          latitude: 59.495069,
          longitude: 15.086444
        },
        {
          number: 18,
          latitude: 59.498186,
          longitude: 15.082470
        }
      ]
    },
    {
      id: 5,
      name: "Kinda GK",
      holes: [
        {
          number: 1,
          latitude: 57.982632,
          longitude: 15.524803
        },
        {
          number: 2,
          latitude: 57.985317,
          longitude: 15.520511
        },
        {
          number: 3,
          latitude: 57.982186,
          longitude: 15.523848
        },
        {
          number: 4,
          latitude: 57.982433,
          longitude: 15.521724
        },
        {
          number: 5,
          latitude: 57.98521,
          longitude: 15.517774
        },
        {
          number: 6,
          latitude: 57.982762,
          longitude: 15.520892
        },
        {
          number: 7,
          latitude: 57.983166,
          longitude: 15.517547
        },
        {
          number: 8,
          latitude:57.98594,
          longitude: 15.515045
        },
        {
          number: 9,
          latitude: 57.986187,
          longitude:  15.520222
        }
      ]
    },
    {
      id: 6,
      name: "Landeryds Norra",
      holes: [
        {
          number: 1,
          latitude: 58.363983,
          longitude: 15.707323
        },
        {
          number: 2,
          latitude: 58.365179,
          longitude: 15.710092
        },
        {
          number: 3,
          latitude: 58.366343,
          longitude: 15.715266 
        },
        {
          number: 4,
          latitude: 58.362505,
          longitude: 15.718157 
        },
        {
          number: 5,
          latitude: 58.363575,
          longitude: 15.725136 
        },
        {
          number: 6,
          latitude: 58.359716,
          longitude: 15.726108 
        },
        {
          number: 7,
          latitude: 58.357100,
          longitude: 15.725501 
        },
        {
          number: 8,
          latitude: 58.358163,
          longitude: 15.721968 
        },
        {
          number: 9,
          latitude: 58.358760,
          longitude: 15.714994 
        },
        {
          number: 10,
          latitude: 58.358064,
          longitude: 15.720156 
        },
        {
          number: 11,
          latitude: 58.356792,
          longitude: 15.720502 
        },
        {
          number: 12,
          latitude: 58.354530,
          longitude: 15.727214 
        },
        {
          number: 13,
          latitude: 58.358879,
          longitude: 15.726049 
        },
        {
          number: 14,
          latitude: 58.361997,
          longitude: 15.722063 
        },
        {
          number: 15,
          latitude: 58.360689,
          longitude: 15.720765 
        },
        {
          number: 16,
          latitude: 58.364327,
          longitude: 15.713561 
        },
        {
          number: 17,
          latitude: 58.364614,
          longitude: 15.709795 
        },
        {
          number: 18,
          latitude: 58.361102,
          longitude: 15.711254 
        }
      ]
    },
    {
      id: 7,
      name: "Bonalba",
      holes: [
        {
          number: 1,
          latitude: 38.443601,
          longitude: -0.435832
        },
        {
          number: 2,
          latitude: 38.447758,
          longitude: -0.431789
        },
        {
          number: 3,
          latitude: 38.449444,
          longitude: -0.429398
        },
        {
          number: 4,
          latitude: 38.449314,
          longitude: -0.427697
        },
        {
          number: 5,
          latitude: 38.446617,
          longitude: -0.431546
        },
        {
          number: 6,
          latitude: 38.444905,
          longitude: -0.433795
        },
        {
          number: 7,
          latitude: 38.446520,
          longitude: -0.429913
        },
        {
          number: 8,
          latitude: 38.445099,
          longitude: -0.428567
        },
        {
          number: 9,
          latitude: 38.442767,
          longitude: -0.431629
        },
        {
          number: 10,
          latitude: 38.440885,
          longitude: -0.435279
        },
        {
          number: 11,
          latitude: 38.441201,
          longitude: -0.436348
        },
        {
          number: 12,
          latitude: 38.438811,
          longitude: -0.438725
        },
        {
          number: 13,
          latitude: 38.442816,
          longitude: -0.440691
        },
        {
          number: 14,
          latitude: 38.444480,
          longitude: -0.441174
        },
        {
          number: 15,
          latitude: 38.439986,
          longitude: -0.443069
        },
        {
          number: 16,
          latitude: 38.438700,
          longitude: -0.439743
        },
        {
          number: 17,
          latitude: 38.439385,
          longitude: -0.435445
        },
        {
          number: 18,
          latitude: 38.442198,
          longitude: -0.431846
        }
      ]
    },
    {
      id: 8,
      name: "Alenda",
      holes: [
        {
          number: 1,
          latitude: 38.361700,
          longitude: -0.677448
        },
        {
          number: 2,
          latitude: 38.361632,
          longitude: -0.673490
        },
        {
          number: 3,
          latitude: 38.360442,
          longitude: -0.671873
        },
        {
          number: 4,
          latitude: 38.360037,
          longitude: -0.668025
        },
        {
          number: 5,
          latitude: 38.359515,
          longitude: -0.669439
        },
        {
          number: 6,
          latitude: 38.358232,
          longitude: -0.665834
        },
        {
          number: 7,
          latitude: 38.358487,
          longitude: -0.669259
        },
        {
          number: 8,
          latitude: 38.355995,
          longitude: -0.667988
        },
        {
          number: 9,
          latitude: 38.359064,
          longitude: -0.671166
        },
        {
          number: 10,
          latitude: 38.358408,
          longitude: -0.675388
        },
        {
          number: 11,
          latitude: 38.360055,
          longitude: -0.678442
        },
        {
          number: 12,
          latitude: 38.357241,
          longitude: -0.675015
        },
        {
          number: 13,
          latitude: 38.355815,
          longitude: -0.674111
        },
        {
          number: 14,
          latitude: 38.354405,
          longitude: -0.669394
        },
        {
          number: 15,
          latitude: 38.356616,
          longitude: -0.671829
        },
        {
          number: 16,
          latitude: 38.355156,
          longitude: -0.671619
        },
        {
          number: 17,
          latitude: 38.357693,
          longitude: -0.674867
        },
        {
          number: 18,
          latitude: 38.358095,
          longitude: -0.671879
        }
      ]
    }
  ]
};

export default Data;