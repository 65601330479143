import './Logo.css';
import { GiGolfTee } from "react-icons/gi";

function Logo(props) {
  return (
    <div className="logo">
      <GiGolfTee size={props.iconSize} color={props.color} />
      <div className="logo-text" style={{fontSize: props.fontSize}}>Golf Meter</div>
    </div>
  );
}

export default Logo;